import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationStart, NavigationEnd, NavigationCancel } from '@angular/router';
import { LoaderService } from './service/loader/loader.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy{
  title = 'ibc-member-panel';

  private loaderTimeOut: any;
  private routerSubscription: Subscription;
  constructor(private router: Router, public ui: LoaderService) {}

  ngOnInit() {
    this.routerSubscription = this.router.events.subscribe(event => {
        if (event instanceof NavigationStart) {
          this.ui.loader.show();
        } else if (event instanceof NavigationEnd) {
          this.loaderTimeOut = setTimeout(() => {
            this.ui.loader.hide();
          }, 500);
        } else if (event instanceof NavigationCancel) {
          this.ui.loader.hide();
        }
    });
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
    clearTimeout(this.loaderTimeOut);
  }
}
