import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, pipe } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as aws4 from "ngx-aws4";
import Amplify, { Auth } from 'aws-amplify';
import { ToastrService } from 'ngx-toastr';

@Injectable()

export class TokenInterceptor implements HttpInterceptor {
  signature:any;

  constructor(private router: Router, private toastr: ToastrService, ) {
    // Amplify.configure({
    //   aws_project_region: 'us-east-1',
    //   aws_cognito_region: 'us-east-1',
    //   aws_user_pools_id: 'us-east-1_fNe82ZlGt',
    //   aws_user_pools_web_client_id: '4upbvt6u4f5un7b1056v6lhkta',
    //   oauth: {},
    //   Auth: {
    //     identityPoolId: 'us-east-1:b6abd23c-e297-4c42-9d1a-cfd47837beca',
    //     region: 'us-east-1',
    //   },
    // });
    
   //prod
Amplify.configure({
  aws_project_region: 'us-east-1',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_CnLNhoTyQ',
  aws_user_pools_web_client_id: '26t8sbvrk0cofkhs9gp19v3ooc',
  oauth: {},
  Auth: {
    identityPoolId: 'us-east-1:8050d589-898c-40e2-ab52-f505300d90b4',
    region: 'us-east-1',
  },
});
    console.log('interceptor called')

    setInterval(()=> {
       console.log('interval')
       this.refreshToken();
    },1000 * 60 * 15)
  }

  getAWSCred(){  
  
      let cognitoUser,currentSession;
         Auth.currentSession()
        .then((data) => console.log(data))
        .catch((err) => console.log(err))

        // currentSession = cognitoUser?.signInUserSession;
        // if(currentSession?.refreshToken?.token) {

        //     this.refreshToken(cognitoUser,currentSession.refreshToken.token)
            
        
        // }  else {
        //   setTimeout(async () => {
        //     this.toastr.clear()
            
        //     this.toastr.warning('Your session is Expired. Please login again to continue.')
        //     try {
        //       await Auth.signOut();
        //       localStorage.setItem('isLoggedIn','false')
        //       this.router.navigate(['/login']);
        //     } catch (error) {
        //       console.log('error signing out: ', error);
        //     }
        //   }, 10);   
        // }
    } 

   async refreshToken(){
     if(localStorage.getItem('isLoggedIn') === 'true') {
       try{

         let cognitoUser, currentSession;
           cognitoUser = await Auth.currentAuthenticatedUser();
           currentSession = await Auth.currentSession();
           cognitoUser.refreshSession(currentSession.refreshToken, async (err, session) => {
             const credential = await Auth.currentCredentials()
             console.log(credential)
             localStorage.setItem('accessId', credential.accessKeyId);
             localStorage.setItem('secretAccessKey', credential.secretAccessKey);
             localStorage.setItem('sessionToken', credential.sessionToken);
             localStorage.setItem('region', credential.identityId.split(':')[0])
             this.reloadComponent();
          });
       } catch(e) {
         this.toastr.clear()
           setTimeout(async () => {    
               this.toastr.warning('Your session is Expired. Please login again to continue.')
               try {
                 await Auth.signOut();
                 localStorage.setItem('isLoggedIn','false')
                 localStorage.clear()
                 this.router.navigate(['/login']);
               } catch (error) {
                 console.log('error signing out: ', error);
               }
             }, 10);   
        }
     }
   }

  // refreshToken(cognitoUser,token) {
  //   cognitoUser.refreshSession(token, async (err, session) => {
        
  //     const credential = await Auth.currentCredentials()
  //     console.log('cog user', credential)
  //     localStorage.setItem('accessId', credential.accessKeyId);
  //     localStorage.setItem('secretAccessKey', credential.secretAccessKey);
  //     localStorage.setItem('sessionToken', credential.sessionToken);
  //     localStorage.setItem('region', credential.identityId.split(':')[0])
  //     setTimeout(() => {
  //       this.reloadComponent();
  //     },1000)

  //     // do something with the new session
  //     console.log('refereshed',session)
  //   });
  // }

  reloadComponent() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
        console.log(currentUrl);
    });
    }

// FOR GET REQUEST
  public getAWS4Signature(url, method, credential) {
    let urlArr = url.split('/')
    let path = ''
      let pathArr = urlArr.splice(3)
      for(let i of pathArr){
        path = `${path}/${i}`
      }

      let requestOptions: any = {
          host: urlArr[2],
          path: path,
          service: 'execute-api',
          region: 'us-east-1',
          method: method,
          headers: {
              'Content-Type': 'application/json'
          }
      }

      aws4.sign(requestOptions, {
        accessKeyId: credential.accessKey,
        secretAccessKey: credential.secretKey,
        sessionToken: credential.secretToken
    })
    return requestOptions;
    }
  
// FOR OTHER REQUEST
    public putAWS4Signature(url, method,credential, body) {
      let urlArr = url.split('/')
      let path = ''
        let pathArr = urlArr.splice(3)
        for(let i of pathArr){
          path = `${path}/${i}`
        }
      let requestOptions: any = {
          host: urlArr[2],
          path: path,
          service: 'execute-api',
          region: 'us-east-1',
          method: method,
          headers: {
              'Content-Type': 'application/json'
              // 'X-Amz-Target': 'DynamoDB_20120810.ListTables'
          },
          body: JSON.stringify(body)
      }

      aws4.sign(requestOptions, {
        accessKeyId: credential.accessKey,
        secretAccessKey: credential.secretKey,
        sessionToken: credential.secretToken
    })
      return requestOptions;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headerOption;
    let credential = {
      accessKey:localStorage.getItem('accessId'),
      secretKey:localStorage.getItem('secretAccessKey'),
      secretToken:localStorage.getItem('sessionToken')
    }

     if(request.method === 'GET') {
        headerOption = this.getAWS4Signature(request.url,request.method,credential)
        delete headerOption.headers.Host
     } else {
        headerOption = this.putAWS4Signature(request.url,request.method,credential, request.body)
        delete headerOption.headers.Host
     }

     if(request.headers.get('skikInterceptor')) {
       console.log('here')
      console.log("This call is to skip adding auth");
      const newHeaders = request.headers.delete('Anonymous')
      // const newRequest = request.clone({ headers: newHeaders });
      // return next.handle(newRequest);
      return next.handle( 
        request.clone({ headers: newHeaders })).pipe(
          catchError(err => {
            const error = err.error.errorMessage || err.error.message || err.statusText;
            console.log(error);
            return throwError(error);
          })
        );
     } else {
      return next.handle(
      
        request.clone({
          // headers: request.headers.append('x-api-key', 'QDXYb146un55XGWEeXuID5sJO1Aqgwzl9wePeyFr'),
          headers: new HttpHeaders(headerOption.headers),
          withCredentials: false
        })).pipe(
          catchError(err => {
            if (err.status === 0) {
              try {
                this.toastr.clear()
                setTimeout(async () => {    
                    this.toastr.warning('Your session is Expired. Please login again to continue.')
                    try {
                      await Auth.signOut();
                      localStorage.setItem('isLoggedIn','false')
                      await Auth.signOut();
                      localStorage.clear()
                      this.router.navigate(['/login']);
                    } catch (error) {
                      console.log('error signing out: ', error);
                    }
                  }, 10);          
              } catch (e) {
                return throwError(e)
              }
            }else if (err.status === 501){
              this.toastr.warning("Fill all the required fields");
            }
            else if (err.status === 412) {
              this.toastr.warning(err.error.message);
            }
            else if (err.status === 406) {
              this.toastr.warning(err.error.message);
            }  else {
              console.log(err.error.message.error[0].message) 
            }
          })
        );
     }


  }
}
