import { RouteInfo } from './sidebar.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [

    // {path: '/user/dashboard', title: 'Dashboard', class:'', icon: 'ft-home',  badge: '', badgeClass: '', isExternalLink: false,  submenu: []},
    { path: '', title: 'Reservations', class:'has-sub', icon: 'ft-file-text', badge: '', badgeClass: '', isExternalLink: false, submenu: [
        { path: '/user/calendar-view', title: 'Calendar View', icon: 'ft-calendar', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/user/current-reservation', title: 'List View', icon: 'ft-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        // { path: '/user/reservation-history', title: 'History', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        // { path: '/user/invoice', title: 'Invoices', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ] },
    // { path: '/user/fleet', title: 'Fleet', icon: 'ft-layout', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu:[]},
    // { path: '/user/pack', title: 'Pack', icon: 'ft-layers', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []},
    {
        path: '', title: 'Profile', icon: 'ft-user', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: '/user/profile', title: 'View Profile', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/user/change-pass', title: 'Change Password', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    }
];
