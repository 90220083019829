// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
//  production: false,
//   host: 'j8nksd1h7a.execute-api.us-east-1.amazonaws.com',
//   memberUrl: 'https://j8nksd1h7a.execute-api.us-east-1.amazonaws.com/dev',
//   commonUrl: 'https://w4egk3oeq2.execute-api.us-east-1.amazonaws.com/dev',
  

  production: true,
  host: 'obdeh66rvc.execute-api.us-east-1.amazonaws.com',
  memberUrl: 'https://obdeh66rvc.execute-api.us-east-1.amazonaws.com/prod',
  commonUrl: 'https://obm174idqi.execute-api.us-east-1.amazonaws.com/prod'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
