<!-- Sidebar Header starts -->
<div class="sidebar-header">
  <div class="logo clearfix">
      <div class="logo-text float-left">
          <div class="logo-img">
              <img src="assets/img/logo.png" class="img-fluid" />
          </div>
          <span class="text align-middle">IBC</span>
        </div>
      <a class="nav-toggle d-none d-sm-none d-md-none d-lg-block" id="sidebarToggle" href="javascript:;">
          <i class="ft-toggle-right toggle-icon" data-toggle="expanded"></i>
      </a>
      <a class="nav-close d-block d-md-block d-lg-none d-xl-none" id="sidebarClose" href="javascript:;">
          <i class="ft-x"></i>
      </a>
  </div>
</div>
<!-- Sidebar Header Ends -->

<!-- Sidebar Content starts -->
<div class="sidebar-content">
  <div class="nav-container">
      <ul class="navigation">
          <!-- First level menu -->
           <li *ngFor="let menuItem of menuItems" [ngClass]="[menuItem.class]" [routerLinkActive]="menuItem.submenu.length != 0 ? '' : 'active'"
              [routerLinkActiveOptions]="{exact: true}">
              <a [routerLink]="menuItem.class === '' ? [menuItem.path] : null" *ngIf="!menuItem.isExternalLink; else externalLinkBlock">
                  <i [ngClass]="[menuItem.icon]"></i>
                  <span class="menu-title">{{menuItem.title}}</span>
                  <span *ngIf="menuItem.badge != '' " [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
              </a>
              <ng-template #externalLinkBlock>
                  <a [href]="[menuItem.path]" target="_blank">
                      <i [ngClass]="[menuItem.icon]"></i>
                      <span class="menu-title">{{menuItem.title}}</span>
                      <span *ngIf="menuItem.badge != '' " [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
                  </a>
              </ng-template>
              <!-- Second level menu -->
              <ul class="menu-content" *ngIf="menuItem.submenu.length > 0">
                  <li *ngFor="let menuSubItem of menuItem.submenu" [routerLinkActive]="menuSubItem.submenu.length > 0 ? '' : 'active'" [ngClass]="[menuSubItem.class]">
                      <a [routerLink]="menuSubItem.submenu.length > 0 ? null : [menuSubItem.path]" *ngIf="!menuSubItem.isExternalLink; else externalSubLinkBlock">
                          <i [ngClass]="[menuSubItem.icon]"></i>
                          <span class="menu-title">{{menuSubItem.title}}</span>
                          <span *ngIf="menuSubItem.badge != '' " [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
                      </a>
                      <ng-template #externalSubLinkBlock>
                          <a [href]="[menuSubItem.path]">
                              <i [ngClass]="[menuSubItem.icon]"></i>
                              <span class="menu-title">{{menuSubItem.title}}</span>
                              <span *ngIf="menuSubItem.badge != '' " [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
                          </a>
                      </ng-template>
                      <!-- Third level menu -->
                      <ul class="menu-content" *ngIf="menuSubItem.submenu.length > 0">
                          <li *ngFor="let menuSubsubItem of menuSubItem.submenu" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                              [ngClass]="[menuSubsubItem.class]">
                              <a [routerLink]="[menuSubsubItem.path]" *ngIf="!menuSubsubItem.isExternalLink; else externalSubSubLinkBlock">
                                  <i [ngClass]="[menuSubsubItem.icon]"></i>
                                  <span class="menu-title">{{menuSubsubItem.title}}</span>
                                  <span *ngIf="menuSubsubItem.badge != '' " [ngClass]="[menuSubsubItem.badgeClass]">{{menuSubsubItem.badge}}</span>
                              </a>
                              <ng-template #externalSubSubLinkBlock>
                                  <a [href]="[menuSubsubItem.path]">
                                      <i [ngClass]="[menuSubsubItem.icon]"></i>
                                      <span class="menu-title">{{menuSubsubItem.title}}</span>
                                      <span *ngIf="menuSubsubItem.badge != '' " [ngClass]="[menuSubsubItem.badgeClass]">{{menuSubsubItem.badge}}</span>
                                  </a>
                              </ng-template>
                          </li>
                      </ul>
                  </li>
              </ul>
          </li>
      </ul>


          <div id="footer">
            <a href="javascript:void(0)"  (click)="open()" class="btn actn-line-btn w-100">Contact Support</a>
        </div>

  </div>
</div>
<!-- Sidebar Content Ends -->

<ng-template  #mymodal2 let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Contact Support</h4>
        <!-- (click)="modal.dismiss('Cross click')" -->
        <button type="button" class="close" aria-label="Close" style="outline: none;" (click)="closeModal()" >
            <span  aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <ul class="d-none d-sm-block">
                <li><a href="tel:801-210-9237" class="pr-4 text-yellow"><i class="fa fa-phone mr-2"></i>801-210-9237</a></li>
                <li><a href="sms:(385)363-5790" class="pr-4 text-yellow"><i class="fa fa-commenting mr-2"></i>385-363-5790</a></li>
                <li><a href="mailto:contact@inlandboatclub.com"><i class="fa fa-envelope mr-2"></i>contact@inlandboatclub.com</a></li>
            </ul>
        </div>
    </div>
</ng-template>