import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthService } from './service/auth/auth.service';
import { NoAuthService } from './service/no-auth/no-auth.service';


const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('../app/components/login/login.module').then( m => m.LoginModule),
    canActivate: [NoAuthService]
  },
  {
    path: 'send-otp',
    loadChildren: () => import('../app/components/forgot-password/forgot-password.module').then( m => m.ForgotPasswordModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('../app/components/reset-password/reset-password.module').then( m => m.ResetPasswordModule)
  },
  {
    path: 'new-password',
    loadChildren: () => import('../app/components/new-password/new-password.module').then( m => m.NewPasswordModule)
  },
  {
    path: 'confirm-otp',
    loadChildren: () => import('../app/components/confirm-otp/confirm-otp.module').then( m => m.ConfirmOtpModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path:'user',
    loadChildren:()=> import ('../app/components/container/container.module').then(m=>m.ContainerModule),

  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
