import { Component, OnInit } from '@angular/core';
import Amplify, { Auth } from 'aws-amplify';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from "@angular/router";
import { LoaderService } from 'src/app/service/loader/loader.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit  {

  currentLang = 'en';
  toggleClass = 'ft-maximize';
  placement = 'bottom-right'
  public isCollapsed = true;

  constructor(public translate: TranslateService, private router: Router,
    private route: ActivatedRoute) {
      const browserLang: string = translate.getBrowserLang();
      translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : 'en');
  }

  ngOnInit(): void {
    Amplify.configure({
      aws_project_region: 'us-east-1',
      aws_cognito_region: 'us-east-1',
      aws_user_pools_id: 'us-east-1_fNe82ZlGt',
      aws_user_pools_web_client_id: '4upbvt6u4f5un7b1056v6lhkta',
      oauth: {},
      Auth: {
        identityPoolId: 'us-east-1:b6abd23c-e297-4c42-9d1a-cfd47837beca',
        region: 'us-east-1',
      },
    });
  }

  ChangeLanguage(language: string) {
      this.translate.use(language);
  }

  ToggleClass() {
      if (this.toggleClass === 'ft-maximize') {
          this.toggleClass = 'ft-minimize';
      }
      else
          this.toggleClass = 'ft-maximize'
  }


  Logout(){
    this.signOut();
    localStorage.setItem('isLoggedIn','false')
    localStorage.clear()
    this.router.navigate(['/login']);
  }

  async signOut() {
    try {
        await Auth.signOut();        
    } catch (error) {
        console.log('error signing out: ', error);
    }
}

}
