import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { SharedRoutingModule } from './shared-routing.module';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ToggleFullscreenDirective } from "./directives/toggle-fullscreen.directive";
import { CustomizerComponent } from './customizer/customizer.component';
import { FooterComponent } from './footer/footer.component';

@NgModule({
  declarations: [NavbarComponent,
                SidebarComponent,
                CustomizerComponent,
                FooterComponent,
                ToggleFullscreenDirective],
  imports: [
    CommonModule,
    SharedRoutingModule,
    FlexLayoutModule,
    NgbModule,
    TranslateModule
  ],
  providers: [

  ],
  exports:[SidebarComponent,
           NavbarComponent,
           CustomizerComponent,
           FooterComponent,
           ToggleFullscreenDirective,
           NgbModule,
           TranslateModule]
})
export class SharedModule { }
